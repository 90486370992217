// src/hooks/useProgress.js

import { useState, useRef, useCallback, useEffect } from 'react';

function useProgress() {
  const [progress, setProgress] = useState(0);
  const progressIntervalRef = useRef(null);
  const startTimeRef = useRef(null);

  const startProgress = useCallback(() => {
    // Reset progress and record the start time
    setProgress(0);
    startTimeRef.current = Date.now();

    // Clear any existing intervals
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    // Set up an interval to update progress every second
    progressIntervalRef.current = setInterval(() => {
      const elapsed = Date.now() - startTimeRef.current; // Elapsed time in ms
      const elapsedSeconds = Math.floor(elapsed / 1000); // Elapsed time in seconds

      let newProgress = progress; // Initialize with current progress

      if (elapsedSeconds <= 60) {
        // First Minute: 0% to 50%
        newProgress = Math.min((elapsedSeconds / 60) * 50, 50);
      } else if (elapsedSeconds <= 120) {
        // Second Minute: 50% to 75%
        newProgress = Math.min(50 + ((elapsedSeconds - 60) / 60) * 25, 75);
      } else if (elapsedSeconds <= 180) {
        // Third Minute: 75% to 99%
        newProgress = Math.min(75 + ((elapsedSeconds - 120) / 60) * 24, 99);
      } else {
        // After 3 Minutes: Stop at 99%
        newProgress = 99;
      }

      setProgress(newProgress);

      // If progress has reached 99%, stop the interval
      if (newProgress >= 99) {
        clearInterval(progressIntervalRef.current);
        progressIntervalRef.current = null;
      }
    }, 1000); // Update every second
  }, []);

  const clearProgress = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
    setProgress(0);
    startTimeRef.current = null;
  }, []);

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      clearProgress();
    };
  }, [clearProgress]);

  return { progress, startProgress, clearProgress };
}

export default useProgress;
