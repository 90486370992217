// src/hooks/useCreateStory.js

import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { UUIDContext } from '../contexts/UUIDContext';
import { useAuth0 } from '@auth0/auth0-react';
import {
  canMakeCreateStoryRequest,
  addCreateStoryTimestamp,
} from '../utils/rateLimiter';

const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

const useCreateStory = (
  formValues,
  subscriptionStatus,
  setErrorMessage,
  setOpenErrorSnackbar,
  setIsLoading,
  setOpenLoadingSnackbar,
  setOpenSuccessSnackbar,
  clearProgress,
  recaptchaRef,
  isFormValid,
  isLoading,
  startProgress // Accept startProgress as a parameter
) => {
  const [uuid, setUuid] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { addUUID } = useContext(UUIDContext);
  const navigate = useNavigate();
  const shareTimerRef = useRef(null);

  // Handle Create Story with CAPTCHA Token
  const handleCreateStory = async (captchaToken, isPremiumFlag) => {
    // Bepaal het tijdsvenster op basis van de abonnementsstatus
    // const windowMinutes = subscriptionStatus === 'premium' ? 5 : 15;

    // // Controleer of de gebruiker een verzoek kan maken binnen het tijdsvenster
    // if (!canMakeCreateStoryRequest(windowMinutes)) {
    //   if (subscriptionStatus === 'premium') {
    //     setErrorMessage(
    //       'Je kunt een verhaal maken elke 5 minuten. Probeer het later opnieuw.'
    //     );
    //   } else {
    //     setErrorMessage(
    //       'Als je niet bent ingelogd, kun je maar 1 verhaal maken elke 15 minuten. Maar als je wel bent ingelogd, kun je dit vaker. Daarnaast kun je verhalen verder uitbreiden én heb je je eigen bibliotheek met al je verhalen! 🎉'
    //     );
    //   }
    //   setOpenErrorSnackbar(true);
    //   return;
    // }

    const { hoofdrolspelers, verhaallocatie, verhaallijn } = formValues;
    if (
      hoofdrolspelers.trim() === '' ||
      verhaallocatie.trim() === '' ||
      verhaallijn.trim() === ''
    ) {
      setErrorMessage('Vul alstublieft alle verplichte velden in.');
      setOpenErrorSnackbar(true);
      return;
    }

    // Start loading
    setIsLoading(true);
    setOpenLoadingSnackbar(true);
    startProgress(); // Use startProgress passed from HomePage.js

    try {
      // Retrieve the access token
      let accessToken = '';
      if (isAuthenticated) {
        try {
          accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_AUDIENCE,
              scope: "read:current_user",
            },
          });
        } catch (tokenError) {
          console.error('Error fetching access token:', tokenError);
          setErrorMessage('Authenticatie is mislukt. Probeer het opnieuw.');
          setIsLoading(false);
          setOpenLoadingSnackbar(false);
          setOpenErrorSnackbar(true);
          clearProgress();
          return;
        }
      }

      // Prepare request body
      const requestBody = {
        ...formValues,
        captchaToken,
        email: isAuthenticated ? user.email : '',
        isPremium: isPremiumFlag, // Explicitly set isPremium
      };

      // Set up headers with Authorization if authenticated
      const headers = {
        'Content-Type': 'application/json',
      };

      if (isAuthenticated && accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
        console.log('This is the headers!!!')
        console.log(headers)
      }

      // Send the request to the backend
      const response = await fetch('/api/create-story', {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });

      ReactGA.event({
        category: 'Story',
        action: 'story_initiated',
        label: 'User initiated story generation',
      });

      const data = await response.json();

      if (response.ok) {
        // Voeg de huidige timestamp toe aan de rate limiter
        addCreateStoryTimestamp();

                // Consume a credit by calling the /api/consume-credit endpoint
        if (isAuthenticated && user.email) {
          try {
            const consumeResponse = await fetch('/api/consume-credit', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
              },
              body: JSON.stringify({ email: user.email }),
            });

            if (!consumeResponse.ok) {
              throw new Error('Failed to consume credit.');
            }

            console.log(`Consumed 1 credit for email: ${user.email}`);
          } catch (error) {
            console.error('Error consuming credit:', error);
            // Optionally, handle the error, e.g., show a notification to the user
          }
        }

        // Poll the job status until it is completed
        const jobId = data.jobId; // Receive jobId from the backend

        const pollJobStatus = async () => {
          try {
            // Optionally include Authorization header in polling if required
            const pollHeaders = {};
            if (isAuthenticated && accessToken) {
              pollHeaders['Authorization'] = `Bearer ${accessToken}`;
            }

            const statusResponse = await fetch(`/api/story-status/${jobId}`, {
              method: 'GET',
              headers: pollHeaders,
            });
            const statusData = await statusResponse.json();

            if (statusData.status === 'completed') {
              const retrievedUuid = statusData.uuid;
              setUuid(retrievedUuid);
              addUUID(retrievedUuid);

              setIsLoading(false);
              setOpenLoadingSnackbar(false);
              setOpenSuccessSnackbar(true);
              clearProgress();

              // Log the story creation event
              ReactGA.event({
                category: 'Story',
                action: 'story_completed',
                label: 'User completed story generation',
              });

              // Redirect to StoryDisplay with UUID
              navigate(`/story/${retrievedUuid}`);

              shareTimerRef.current = setTimeout(() => {
                // Handle any actions after story generation, if needed
              }, 120000);
            } else if (statusData.status === 'failed') {
              throw new Error(statusData.reason || 'create_story_job_failed');
            } else {
              // Still in progress; poll again after a delay
              setTimeout(pollJobStatus, 3000); // Poll every 3 seconds
            }
          } catch (error) {
            console.error('Error polling job status:', error);
            setErrorMessage(error.message || 'Er is een onverwachte fout opgetreden.');
            setIsLoading(false);
            setOpenLoadingSnackbar(false);
            setOpenErrorSnackbar(true);
            clearProgress();

            // Log the error event
            ReactGA.event({
              category: 'Error',
              action: 'create_story_failed',
              label: error.message || 'Unknown error',
            });
          }
        };

        // Start polling
        pollJobStatus();
      } else {
        throw new Error(data.error || 'Er is een onverwachte fout opgetreden.');
      }
    } catch (error) {
      console.error('Error in handleCreateStory:', error);
      setErrorMessage(error.message || 'Er is een onverwachte fout opgetreden.');
      setIsLoading(false);
      setOpenLoadingSnackbar(false);
      setOpenErrorSnackbar(true);
      clearProgress();

      // Log the error event
      ReactGA.event({
        category: 'Error',
        action: 'create_story_failed',
        label: error.message || 'Unknown error',
      });
    }
  };

  // Handle Standard Story submission via reCAPTCHA
  const handleStandardSubmit = () => {
    if (!isFormValid || isLoading) {
      return;
    }
    setIsPremium(false); // Set isPremium to false for standard stories
    if (recaptchaRef.current) {
      recaptchaRef.current.execute(); // Execute Invisible reCAPTCHA
    }
  };

  // Handle Premium Story submission via reCAPTCHA
  const handlePremiumSubmit = () => {
    if (!isFormValid || isLoading) {
      return;
    }
    setIsPremium(true); // Set isPremium to true for premium stories
    if (recaptchaRef.current) {
      recaptchaRef.current.execute(); // Execute Invisible reCAPTCHA
    }
  };

  // Handle reCAPTCHA verification
  const onReCAPTCHAChange = (token) => {
    if (token) {
      handleCreateStory(token, isPremium); // Pass the current isPremium state
      recaptchaRef.current.reset(); // Reset for future use
      setIsPremium(false); // Reset isPremium state
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (shareTimerRef.current) {
        clearTimeout(shareTimerRef.current);
      }
    };
  }, []);

  return {
    handleStandardSubmit,
    handlePremiumSubmit,
    onReCAPTCHAChange,
    uuid,
  };
};

export default useCreateStory;
