// src/components/AbonnementenModal.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Box,
  useTheme,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star'; // Icon for Free Plan
import ChildCareIcon from '@mui/icons-material/ChildCare'; // Icon for Child Bundle
import SchoolIcon from '@mui/icons-material/School'; // Icon for Bonus Bundle
import ContactMailIcon from '@mui/icons-material/ContactMail'; // Icon for Custom Plan
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import axios from 'axios'; // Import axios
import { UserStatusContext } from '../contexts/UserStatusContext'; // Import UserStatusContext

// Create the Context
const AbonnementenModalContext = createContext();

// Custom hook for easier context consumption
export const useAbonnementenModal = () => {
  return useContext(AbonnementenModalContext);
};

// Provider Component
export const AbonnementenModalProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0(); // Access user info
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme(); // Access current theme

  const { userCredits } = useContext(UserStatusContext); // Access userCredits from context
  const [daysUntilRefill, setDaysUntilRefill] = useState(null); // State for days until refill

  // Extract user's first name for personalization
  const userName = user?.name?.split(' ')[0] || 'lezer';

  // Calculate the number of days until the next month
  useEffect(() => {
    const calculateDaysUntilRefill = () => {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const diffTime = nextMonth - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysUntilRefill(diffDays);
    };

    calculateDaysUntilRefill();

    // Optional: Update the countdown daily
    const interval = setInterval(calculateDaysUntilRefill, 1000 * 60 * 60 * 24);

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  const openAbonnementenModal = () => {
    setIsModalOpen(true);
  };

  const closeAbonnementenModal = () => {
    setIsModalOpen(false);
  };

  // Handler for choosing Child Bundle
  const handleChooseKindbundel = async () => {
    if (!isAuthenticated || !user) {
      alert('U moet ingelogd zijn om een bundel te kiezen.');
      return;
    }

    try {
      const response = await axios.post('/initiate_payment', {
        type: 'premium_child',
        email: user.email,
      });
      // Assuming the response contains a payment URL to redirect the user
      window.location.href = response.data.payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('Er is een fout opgetreden bij het starten van de betaling.');
    }
  };

  // Handler for choosing Bonus Bundle
  const handleChooseKlassenbundel = async () => {
    if (!isAuthenticated || !user) {
      alert('U moet ingelogd zijn om een bundel te kiezen.');
      return;
    }

    try {
      const response = await axios.post('/initiate_payment', {
        type: 'premium_classroom',
        email: user.email,
      });
      // Assuming the response contains a payment URL to redirect the user
      window.location.href = response.data.payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('Er is een fout opgetreden bij het starten van de betaling.');
    }
  };

  return (
    <AbonnementenModalContext.Provider
      value={{
        isModalOpen,
        openAbonnementenModal,
        closeAbonnementenModal,
      }}
    >
      {children}
      {/* Modal Component */}
      <Dialog
        open={isModalOpen}
        onClose={closeAbonnementenModal}
        aria-labelledby="abonnementen-dialog-title"
        aria-describedby="abonnementen-dialog-description"
        maxWidth="lg" // Increase width to accommodate the Free option
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 4,
            boxShadow: 24,
            backgroundColor: theme.palette.background.default, // Update the background to match the theme
          },
        }}
      >
        <DialogTitle id="abonnementen-dialog-title" sx={{ textAlign: 'center', paddingBottom: 0 }}>
          <Typography variant="h4" color="primary">
            Verhalen Bundels
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* Personal message with credits */}
          {isAuthenticated && userCredits !== null && (
            <Typography variant="body1" align="center" gutterBottom sx={{ marginTop: 2 }}>
              Hi {userName}, op dit moment heb jij nog <strong>{userCredits}</strong> verhalen-credits over.
              Deze worden op de eerste dag van de volgende maand aangevuld door ons, maar je kan natuurlijk ook een betaalde bundel kopen (en daarmee dit mooie initiatief supporten).
            </Typography>
          )}
          <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 2 }}>
            {/* Free Option */}
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <StarIcon sx={{ fontSize: 40, mr: 1, color: theme.palette.primary.main }} />
                    <Typography variant="h6" component="div" color="text.primary">
                      Gratis
                    </Typography>
                  </Box>
                  <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
                    Altijd Gratis
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Elke lezer krijgt maandelijks van ons gratis 5 verhalen-credits. Zo kan iedereen blijven genieten van persoonlijke verhalen.
                  </Typography>
                  {/* Countdown Timer */}
                  {isAuthenticated && daysUntilRefill !== null && (
                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                      Volgende aanvulling in: <strong>{daysUntilRefill} dag{daysUntilRefill !== 1 ? 'en' : ''}</strong>
                    </Typography>
                  )}
                </CardContent>
                {/* No CardActions as there is no button */}
              </Card>
            </Grid>

            {/* Child Bundle */}
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <ChildCareIcon sx={{ fontSize: 40, mr: 1, color: theme.palette.primary.main }} />
                    <Typography variant="h6" component="div" color="text.primary">
                      Kindbundel
                    </Typography>
                  </Box>
                  <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
                    €2,99 eenmalig
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Geniet van alle mogelijkheden en ontvang 30 nieuwe verhalen of uitbreidingen inbegrepen. Zo kan je (kind) helemaal los gaan met lezen.
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      height: 50, // Equal height
                      borderRadius: 1,
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    onClick={handleChooseKindbundel} // Add onClick handler
                  >
                    Kies Kindbundel
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Bonus Bundle */}
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: 3,
                  // Removed border and highlight
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <SchoolIcon sx={{ fontSize: 40, mr: 1, color: theme.palette.primary.main }} />
                    <Typography variant="h6" component="div" color="text.primary">
                      Bonusbundel
                    </Typography>
                  </Box>
                  <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
                    €24,99 eenmalig
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Geniet van alle mogelijkheden en ontvang 250 + 50 gratis nieuwe verhalen of uitbreidingen inbegrepen. Zo kan je een veelvoud van verhalen maken (voor de kinderen in de klas).
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      height: 50, // Equal height
                      borderRadius: 1,
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    onClick={handleChooseKlassenbundel} // Add onClick handler
                  >
                    Kies Bonusbundel
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Custom Bundle */}
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <ContactMailIcon sx={{ fontSize: 40, mr: 1, color: theme.palette.primary.main }} />
                    <Typography variant="h6" component="div" color="text.primary">
                      Op Maat bundel
                    </Typography>
                  </Box>
                  <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
                    Organisaties
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Ben je van een organisatie en heb je meer nodig? Neem dan contact met ons op via info@verhalen-maker.nl
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      height: 50, // Equal height
                      borderRadius: 1,
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    href="mailto:info@verhalen-maker.nl"
                  >
                    Neem Contact Op
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeAbonnementenModal}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Sluiten
          </Button>
        </DialogActions>
      </Dialog>
    </AbonnementenModalContext.Provider>
  );
};

export default AbonnementenModalProvider;
