import React from 'react';
import CustomSnackbar from '../../components/CustomSnackbar';

const Snackbars = ({
  openLoadingSnackbar,
  openErrorSnackbar,
  openSuccessSnackbar,
  openShareSnackbar,
  handleCloseSnackbar,
  errorMessage,
}) => {
  return (
    <>
      {/* Loading Snackbar */}
      <CustomSnackbar
        open={openLoadingSnackbar}
        handleClose={handleCloseSnackbar}
        severity="info"
        message="Het verhaal wordt gemaakt, dit kan een paar minuten duren, dus ga gerust je tanden poetsen ;-)"
        autoHideDuration={null}
      />
      {/* Error Snackbar */}
      <CustomSnackbar
        open={openErrorSnackbar}
        handleClose={handleCloseSnackbar}
        severity="error"
        message={errorMessage}
      />
      {/* Success Snackbar */}
      <CustomSnackbar
        open={openSuccessSnackbar}
        handleClose={handleCloseSnackbar}
        severity="success"
        message="Verhaal is gemaakt! Veel leesplezier."
      />
      {/* Share Snackbar (if needed) */}
      {openShareSnackbar && (
        <CustomSnackbar
          open={openShareSnackbar}
          handleClose={handleCloseSnackbar}
          severity="info"
          message="Deel je verhaal met anderen!"
        />
      )}
    </>
  );
};

export default Snackbars;
