// src/pages/PaymentSuccess.js

import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const PaymentSuccess = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh', // Adjusted height for better display within the container
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Bedankt voor je betaling
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 2 }}>
          We zijn je betaling aan het verwerken. Je credits zullen binnen enkele seconden zichtbaar zijn rechtsonderin bij je profiel.
        </Typography>
        <Typography variant="body2" align="center" sx={{ mb: 2 }}>
          Als je credits niet verschijnen, ververs dan de pagina.
        </Typography>
        <Typography variant="body2" align="center">
          Mocht er een probleem optreden, neem dan contact met ons op via{' '}
          <Link href="mailto:info@verhalen-maker.nl" underline="hover">
            info@verhalen-maker.nl
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
