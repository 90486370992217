// src/components/LoginPromptModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SecurityIcon from '@mui/icons-material/Security';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BrushIcon from '@mui/icons-material/Brush';

let setOpenModal = null;

export const showLoginModal = () => {
  if (setOpenModal) {
    setOpenModal(true);
  }
};

// Common button styles extracted for reusability
const commonButtonStyles = {
  borderRadius: '10px',
  textTransform: 'none',
  fontWeight: 600,
  paddingY: 1.5,
  paddingX: 3,
};

const LoginPromptModal = () => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false); // State for expanded accordion
  const { loginWithRedirect } = useAuth0();
  const location = useLocation(); // Get current location
  const theme = useTheme(); // Access the theme

  useEffect(() => {
    setOpenModal = setOpen;
    return () => {
      setOpenModal = null;
    };
  }, []);

  const handleClose = () => {
    console.log('LoginPromptModal: Closing without logging in');
    setOpen(false);
  };

  const handleLogin = () => {
    console.log('LoginPromptModal: Initiating login');
    console.log('LoginPromptModal: Current location:', location.pathname + location.search);

    // Save return path in sessionStorage
    sessionStorage.setItem('returnTo', location.pathname + location.search);

    setOpen(false);
    loginWithRedirect().catch((error) => {
      console.error('LoginPromptModal: loginWithRedirect error:', error);
    });
  };

  // Handler for toggling Accordions
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          boxShadow: 24,
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', paddingBottom: 0 }}>
        <Typography variant="h4" color="primary">
          Hey daar! Tijd om in te loggen
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* Introductory text with Image */}
        <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <img
              src="/jasmijn.png" // Use the jasmijn.png image
              alt="Kinderen maken verhalen"
              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1" gutterBottom>
              Hoi allemaal! 😊 <br /><br />
              In de laatste weken hebben jullie meer dan 10.000 leuke verhalen gemaakt! 🎉 Super tof en bedankt voor jullie enthousiasme! 🙌 <br /><br />
              Om ervoor te zorgen dat we dit ook in de toekomst kunnen blijven doen, is het vanaf nu nodig om in te loggen met een e-mailadres. 📧 Wil je dus blijven verhalen maken? Log dan snel in – het is heel makkelijk! 👍 Als je het niet helemaal snapt, vraag dan je ouders of je juf of meester om hulp. 🧑‍🏫👨‍👩‍👧‍👦 <br /><br />
            </Typography>
          </Grid>
        </Grid>

        {/* Accordion Items */}
        <Box mt={4}>
          {/* Privacy and Security Accordion */}
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <SecurityIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Privacy en Veiligheid</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    Door in te loggen, zorgen we ervoor dat jouw verhalen en gegevens veilig zijn. We beschermen jouw persoonlijke informatie met de beste beveiliging, zodat jij zorgeloos kunt blijven creëren.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/privacy.png" // Use the privacy.png image
                    alt="Privacy en Veiligheid"
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Mijn Bibliotheek Accordion */}
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <LibraryBooksIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Mijn Bibliotheek</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    In jouw bibliotheek kun je al je gemaakte verhalen terugvinden. Je kunt ze blijven lezen en zelfs nieuwe avonturen toevoegen, zodat jouw creativiteit nooit stopt!
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/library.png" // Use the library.png image
                    alt="Mijn Bibliotheek"
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Verhalen Uitbreiden Accordion */}
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <AddCircleIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Verhalen Uitbreiden</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    Nadat je bent ingelogd, kun je je verhalen uitbreiden. Voeg nieuwe hoofdstukken toe aan je favoriete verhalen en laat je fantasie de vrije loop!
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/story_expansion.png" // Use the story_expansion.png image
                    alt="Verhalen Uitbreiden"
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Extra Functies Accordion */}
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ backgroundColor: theme.palette.background.paper }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <BrushIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Extra Functies</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body2">
                    We zijn bezig met het toevoegen van meer plaatjes per verhaal en binnenkort kun je ook naar je boeken luisteren! Zo wordt het nog leuker om van je verhalen te genieten.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="/headphones.png" // Use the headphones.png image
                    alt="Extra Functies"
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
        {/* "Log In of Aanmelden" Button */}
        <Button
          onClick={handleLogin}
          variant="contained"
          color="primary"
          startIcon={<EmojiEmotionsIcon />}
          aria-label="Nu inloggen of aanmelden! 🚀✨"
          sx={{
            ...commonButtonStyles,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          Nu inloggen of aanmelden! 🚀✨
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginPromptModal;
