// src/pages/Contact.js

import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Contact = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh', // Aangepaste hoogte voor betere weergave binnen de container
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Neem contact met ons op.
        </Typography>
        <Typography variant="body1" align="center">
          Heb je vragen of opmerkingen? Stuur ons een e-mail op{' '}
          <Link href="mailto:info@verhalen-maker.nl" underline="hover">
            info@verhalen-maker.nl
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Contact;
