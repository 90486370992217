// src/pages/homepage_components/CreateStoryButtons.js

import React from 'react';
import { Box, Button } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import { useAbonnementenModal } from '../../components/AbonnementenModal'; // Import de custom hook

const CreateStoryButtons = ({
  isFormValid,
  isLoading,
  handleStandardSubmit,
  handlePremiumSubmit,
  subscriptionStatus, // Ontvang de prop
  isAuthenticated,    // Nieuwe prop
  openLoginModal,     // Nieuwe prop toegevoegd

}) => {
  const { openAbonnementenModal } = useAbonnementenModal(); // Destructure de open functie uit context
  const { loginWithRedirect } = useAuth0(); // Haal loginWithRedirect uit Auth0

  // Bepaal de knopstatus en tekst op basis van authenticatie en abonnement
  let buttonContent = null;

  if (!isAuthenticated) {
    // Gebruiker is niet geauthenticeerd
    buttonContent = (
      <Button
        variant="contained"
        color="primary"
        onClick={openLoginModal} // Gebruik openLoginModal in plaats van loginWithRedirect
        // onClick={() => loginWithRedirect()} // Directe login
        disabled={isLoading}
        aria-label="Log in of meld je aan"
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          fontWeight: 600,
          paddingY: 1.5,
          paddingX: 3
        }}
      >
        Log in of meld je aan! 🚀✨
      </Button>
    );
  } else if (isAuthenticated && subscriptionStatus !== 'premium') {
    // Gebruiker is geauthenticeerd maar niet premium
    buttonContent = (
      <Button
        variant="contained"
        color="primary"
        onClick={openAbonnementenModal} // Open de abonnementen modal
        disabled={ isLoading}
        aria-label="Open abonnementen modal"
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          fontWeight: 600,
          paddingY: 1.5,
          paddingX: 3,
          cursor: 'pointer',
          backgroundColor: 'grey.500',
          color: 'white',
          '&:hover': {
            backgroundColor: 'grey.600',
          },
        }}
      >
        Klik om verhalen credits toe te voegen
      </Button>
    );
  } else if (isAuthenticated && subscriptionStatus === 'premium') {
    // Gebruiker is geauthenticeerd en premium
    buttonContent = (
      <Button
        variant="contained"
        startIcon={<CreateIcon />}
        onClick={handlePremiumSubmit}
        disabled={!isFormValid || isLoading}
        aria-label="Maak een verhaal"
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          fontWeight: 600,
          paddingY: 1.5,
          paddingX: 3,
          animation: isFormValid ? 'bounce 1s infinite' : 'none'
        }}
      >
        Maak een verhaal! 👑
      </Button>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 6,
        mt: 4,
        width: '100%',
        flexWrap: 'wrap', // Zorg ervoor dat knoppen omwikkelen op kleinere schermen
        gap: 2, // Voeg ruimte tussen knoppen toe
      }}
    >
      {buttonContent}
    </Box>
  );
};

export default CreateStoryButtons;
