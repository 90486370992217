// src/storyService.js

/**
 * Verbetert een bestaand verhaal op basis van de verstrekte UUID.
 *
 * @param {Object} values - De waarden voor het verbeteren van het verhaal.
 * @param {string} values.uuid - De UUID van het te verbeteren verhaal.
 * @param {string} values.email - De email
 * @param {string} values.captchaToken - De CAPTCHA-token voor verificatie.
 * @param {boolean} values.isPremium - Flag die aangeeft of het een premium verbetering is.
 * @param {string} accessToken - De toegangstoken voor geauthenticeerde verzoeken.

 * @returns {Promise<string>} - Een belofte die de UUID van het verbeterde verhaal oplevert.
 */

// import { showLoginModal } from './components/LoginPromptModal'; // Import the showLoginModal function

export const enhance_story = async (values, accessToken = '') => {
  const { email, uuid, captchaToken, isPremium } = values;

  try {
    // Step 1: Create the enhancement job
    console.log('Verhaalgeneratieverbetering wordt gestart...');
    console.log('Formulierwaarden:', values);

    // Set up headers with Authorization if accessToken is provided
    const headers = {
      'Content-Type': 'application/json',
    };
    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
      console.log('Authorization header toegevoegd:', headers['Authorization']);
    }

    const response = await fetch('/api/enhance-story', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        email,
        uuid,
        captchaToken,
        isPremium, // Directly pass isPremium from the front-end
      }),
    });

    console.log('Verzoek verzonden naar /api/enhance-story');

    if (response.status === 401) {
      // Unauthorized: Prompt user to log in
      console.warn('401 Unauthorized: Gebruiker moet inloggen.');
      throw new Error('Niet geautoriseerd. Inloggen is vereist.');
    }

    if (!response.ok) {
      const errorBody = await response.json();
      console.error('Kan de verbeteringsjob niet aanmaken:', errorBody);
      // Throw the original server error
      throw new Error(errorBody.error || 'Kan de verbeteringsjob niet aanmaken.');
    }

    const data = await response.json();
    console.log('Respons ontvangen van /api/enhance-story:', data);

    const jobId = data.jobId;

    if (!jobId) {
      console.error('Geen jobId ontvangen van de server');
      throw new Error('Geen jobId ontvangen van de server');
    } else {
      // Ensure that consume-credit is only called on successful job creation
      try {
        const consumeResponse = await fetch('/api/consume-credit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ email: email }),
        });

        if (!consumeResponse.ok) {
          throw new Error('Failed to consume credit.');
        }

        console.log(`Consumed 1 credit for email: ${email}`);
      } catch (error) {
        console.error('Error consuming credit:', error);
        // Optionally, handle the error, e.g., show a notification to the user
        // Decide whether to proceed or rollback the job creation
        throw error; // Re-throw if you want to prevent further execution
      }
    }

    console.log(`Verbeteringsjob aangemaakt met ID: ${jobId}`);

    // Step 2: Poll the job status
    const pollInterval = 2000; // 2 seconds
    const maxAttempts = 160; // Wait up to 320 seconds
    let attempts = 0;

    return new Promise((resolve, reject) => {
      const pollJobStatus = async () => {
        try {
          console.log(`Polling jobstatus (Attempt ${attempts + 1}/${maxAttempts})...`);

          const statusResponse = await fetch(`/api/story-status/${jobId}`);

          console.log('Verzoek verzonden naar /api/story-status/:jobId');

          if (!statusResponse.ok) {
            const errorBody = await statusResponse.json();
            console.error('Kan de jobstatus niet ophalen:', errorBody);
            // Throw the original server error
            throw new Error(errorBody.error || 'Kan de jobstatus niet ophalen.');
          }

          const statusData = await statusResponse.json();
          console.log(`Jobstatus ontvangen: ${statusData.status}`);
          console.log('Status Data:', statusData); // Debugging: volledige statusData

          if (statusData.status === 'completed') {
            const { uuid } = statusData;

            if (uuid) {
              console.log('Verhaalgeneratieverbetering succesvol voltooid.');
              console.log(`UUID: ${uuid}`);
              resolve(uuid);
            } else {
              console.error('UUID ontbreekt in de respons.');
              reject(new Error('UUID ontbreekt in de respons.'));
            }
          } else if (statusData.status === 'failed') {
            console.error('Verhaalgeneratieverbetering is mislukt op de server.');
            // Use the server error message if available
            reject(new Error(statusData.error || 'Verhaalgeneratieverbetering is mislukt op de server.'));
          } else {
            // Still in progress; poll again after a delay
            attempts += 1;
            if (attempts < maxAttempts) {
              console.log('Verhaalgeneratieverbetering nog niet voltooid. Wachten...');
              setTimeout(pollJobStatus, pollInterval);
            } else {
              console.error('Verhaalgeneratieverbetering duurt langer dan verwacht.');
              reject(new Error('Verhaalgeneratieverbetering duurt langer dan verwacht. Probeer het later opnieuw.'));
            }
          }
        } catch (error) {
          console.error('Fout bij het pollen van de jobstatus:', error);
          // Preserve the original error message
          reject(error);
        }
      };

      // Start polling
      pollJobStatus();
    });
  } catch (error) {
    console.error('Fout bij het verbeteren van het verhaal:', error);
    // Preserve the original error message or propagate it
    throw error;
  }
};



/**
 * Haalt een verhaal op basis van een UUID.
 *
 * @param {string} uuid - De UUID van het verhaal.
 * @returns {Promise<Object>} - Een belofte die het verhaal en de afbeelding-URL oplevert.
 */
export const fetch_story_by_uuid = async (uuid) => {
  try {
    const response = await fetch(`/api/story/${uuid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log(`Verzoek verzonden naar /api/story/${uuid}`);

    if (!response.ok) {
      const errorBody = await response.json();
      console.error('Kan het verhaal niet ophalen:', errorBody);
      throw new Error(
        `Kan het verhaal niet ophalen: ${errorBody.error || JSON.stringify(errorBody)}`
      );
    }

    const data = await response.json();
    console.log('Respons ontvangen van /api/story/:uuid:', data);

    const { email, story, image_url, ispremium } = data;

    if (!story) {
      throw new Error('Verhaal ontbreekt in de respons.');
    }
    console.log('this is the ispremium set, ', ispremium)
    return { email, story, image_url: image_url || '', ispremium };
  } catch (error) {
    console.error('Fout bij het ophalen van het verhaal:', error);
    throw new Error(
      'Er is een fout opgetreden bij het ophalen van het verhaal. Probeer het opnieuw.'
    );
  }
};
