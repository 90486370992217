// src/pages/AanvragenKlassenlink.js

import React, { useState } from 'react';
import { Typography, Box, TextField, Button, Grid, Alert, Container } from '@mui/material';

const AanvragenKlassenlink = () => {
  const [formData, setFormData] = useState({
    naamLeraar: '',
    email: '',
    telefoon: '',
    naamSchool: '',
    adresSchool: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch('/api/request-class-link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        // Fout afhandeling
        setErrorMessage(data.error || 'Er is een fout opgetreden. Probeer het opnieuw.');
      } else {
        // Succes afhandeling
        setSuccessMessage(data.message || 'Je aanvraag is succesvol verzonden!');
        setFormData({
          naamLeraar: '',
          email: '',
          telefoon: '',
          naamSchool: '',
          adresSchool: '',
        });
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setErrorMessage('Er is een fout opgetreden. Probeer het opnieuw.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Aanvragen Klassenlink
      </Typography>
      <Typography variant="body1" gutterBottom>
        Als leraar of lerares kunt u een <strong>klassenlink</strong> of <strong>klassenurl</strong> aanvragen. Hierdoor hoeven uw leerlingen niet in te loggen om gebruik te maken van de extra functionaliteiten zoals:
      </Typography>
      <ul>
        <li>Uitbreiden van verhalen</li>
        <li>Toegevoegde illustraties</li>
        <li>Voorgelezen krijgen van het verhaal</li>
      </ul>
      <Typography variant="body1" gutterBottom>
        Vul het onderstaande formulier in om een klassenlink aan te vragen.
      </Typography>

      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

      <Box sx={{ p: 3, maxWidth: 600, margin: '0 auto' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Naam leraar/lerares"
                name="naamLeraar"
                value={formData.naamLeraar}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="E-mailadres"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefoonnummer"
                name="telefoon"
                type="tel"
                value={formData.telefoon}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Naam school"
                name="naamSchool"
                value={formData.naamSchool}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Adres school"
                name="adresSchool"
                value={formData.adresSchool}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Verzenden...' : 'Verstuur'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default AanvragenKlassenlink;
