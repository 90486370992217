// src/pages/MijnVerhalenBibliotheek.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Container,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const MijnVerhalenBibliotheek = () => {
  const theme = useTheme();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [verhalen, setVerhalen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVerhalen = async () => {
      if (!isAuthenticated) {
        setLoading(false);
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('/api/verhalen', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setVerhalen(data);
      } catch (err) {
        console.error(err);
        setError('Er is een fout opgetreden bij het ophalen van verhalen.');
      } finally {
        setLoading(false);
      }
    };

    fetchVerhalen();
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading || loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ mb: 4 }} // Added custom bottom margin
      >
        Mijn Verhalen Bibliotheek
      </Typography>
      {verhalen.length === 0 ? (
        <Typography variant="body1" align="center">
          Je hebt nog geen verhalen toegevoegd.
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {verhalen.map((verhaal) => (
            <Grid item xs={12} sm={6} md={4} key={verhaal.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}
              >
                <CardActionArea
                  onClick={() => navigate(`/story/${verhaal.id}`)}
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={verhaal.image ? verhaal.image : '/placeholder.webp'}
                    alt={verhaal.title}
                    sx={{
                      width: '100%',
                      aspectRatio: '1 / 1',
                      objectFit: 'cover',
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ fontWeight: 'bold', textAlign: 'center' }}
                    >
                      {verhaal.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      {verhaal.description.length > 100
                        ? `${verhaal.description.substring(0, 100)}...`
                        : verhaal.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default MijnVerhalenBibliotheek;
