import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCAPTCHAComponent = ({ recaptchaRef, onReCAPTCHAChange }) => {
  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      size="invisible"
      ref={recaptchaRef}
      onChange={onReCAPTCHAChange}
    />
  );
};

export default ReCAPTCHAComponent;
