// src/components/StoryDisplay.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Box,
  Button,
  Fade,
  CircularProgress,
  LinearProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Skeleton,
  Snackbar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { keyframes } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import EnhanceIcon from '@mui/icons-material/Build';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ShareIcon from '@mui/icons-material/Share';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { fetch_story_by_uuid, enhance_story } from '../storyService';
import ReactGA from 'react-ga4';
import { useAuth0 } from '@auth0/auth0-react';
import { showLoginModal } from './LoginPromptModal';
import ReCAPTCHAComponent from '../pages/homepage_components/ReCAPTCHAComponent';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { UserStatusContext } from '../contexts/UserStatusContext';
import { useAbonnementenModal } from './AbonnementenModal';
import {
  canMakeCreateStoryRequest, // Gebruik bestaande functie
  addCreateStoryTimestamp,   // Gebruik bestaande functie
} from '../utils/rateLimiter';

const NoTooltip = ({ children }) => children;

const SmallFontTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
  },
}));

// Define pulsating keyframes
const pulsate = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(103, 58, 183, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(103, 58, 183,  0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(103, 58, 183,  0);
  }
`;

function StoryDisplay() {
  const { uuid } = useParams();
  const [story, setStory] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [base64Image, setBase64Image] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [loadingStory, setLoadingStory] = useState(true);
  const [storyError, setStoryError] = useState(null);
  const [enhanceError, setEnhanceError] = useState(null);

  // States for Email Modal
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // New state for Fade
  const [fadeIn, setFadeIn] = useState(false);

  // New state for ReCAPTCHA
  const recaptchaRef = useRef(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN;
  const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
  const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

  // New state variable for isPremiumStory
  const [isPremiumStory, setIsPremiumStory] = useState(false);

  // New state variables for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');


  const { userCredits } = useContext(UserStatusContext);
  const { openAbonnementenModal } = useAbonnementenModal();
  
  useEffect(() => {
    setFadeIn(false);

    const fetchStory = async () => {
      try {
        const { email, story, image_url, ispremium } = await fetch_story_by_uuid(uuid);
        setStory(story);
        setImageUrl(image_url);
        setEmail(email);
        setIsPremiumStory(ispremium); // Set the isPremiumStory state variable
        setLoadingStory(false);
        console.log('this is the email: ', email);
        console.log('this is premium?: ', ispremium);

        // Log 'created_story_displayed' event after successfully fetching the story
        ReactGA.event({
          category: 'StoryDisplay',
          action: 'created_story_displayed',
          label: 'Story Display Page Loaded',
        });
      } catch (error) {
        console.error('Error fetching story:', error);
        setStoryError(error.message);
        setLoadingStory(false);
      }
    };

    fetchStory();
  }, [uuid]);

  useEffect(() => {
    if (!loadingStory && !storyError) {
      setFadeIn(true);
    }
  }, [loadingStory, storyError]);

  useEffect(() => {
    const fetchBase64Image = async () => {
      if (!imageUrl) return; // Removed isPremiumStory condition to always fetch the image

      setLoadingImage(true);
      setImageError(false);

      try {
        const response = await fetch(`/api/getImage?url=${encodeURIComponent(imageUrl)}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch image.');
        }
        const data = await response.json();
        setBase64Image(data.base64);
      } catch (error) {
        console.error('Error fetching Base64 image:', error);
        setImageError(true);
      } finally {
        setLoadingImage(false);
      }
    };

    fetchBase64Image();
  }, [imageUrl]); // Removed isPremiumStory from dependencies

  const handleDownload = async () => {
    setIsGenerating(true);
    setDownloadError(null);
    try {
      const doc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 40;
      let yPosition = margin;

      // Add Header
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(20);
      doc.text('Verhaal van Verhalen-maker.nl', pageWidth / 2, yPosition, { align: 'center' });
      yPosition += 30;

      // Add Image
      if (base64Image) {
        try {
          const imgProps = doc.getImageProperties(base64Image);
          const imgWidth = pageWidth - 2 * margin;
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

          doc.addImage(base64Image, 'PNG', margin, yPosition, imgWidth, imgHeight);
          yPosition += imgHeight + 20;
        } catch (error) {
          console.error('Error adding image to PDF:', error);
        }
      }

      // Add Story Title
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(16);
      doc.text('Jouw Verhaal', pageWidth / 2, yPosition, { align: 'center' });
      yPosition += 25;

      // Add Story Text
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      const textLines = doc.splitTextToSize(story, pageWidth - 2 * margin);

      const addText = (lines) => {
        let currentY = yPosition;
        lines.forEach((line) => {
          if (currentY > pageHeight - margin) {
            doc.addPage();
            currentY = margin;
          }
          doc.text(line, margin, currentY);
          currentY += 14;
        });
      };

      addText(textLines);

      // Add Page Numbers
      const totalPages = doc.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`Pagina ${i} van ${totalPages}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
      }

      // Save PDF
      doc.save('verhaal.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      setDownloadError('Er is een fout opgetreden bij het genereren van de PDF. Probeer het opnieuw.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Handle sharing via WhatsApp
  const handleShare = () => {
    const shareUrl = `https://${WEBSITE_DOMAIN}/story/${uuid}`;
    const text = encodeURIComponent(`Bekijk dit verhaal: ${shareUrl}`);
    const whatsappUrl = `https://wa.me/?text=${text}`;
    window.open(whatsappUrl, '_blank');

    // Log 'share_whatsapp_clicked' event
    ReactGA.event({
      category: 'User Interaction',
      action: 'share_whatsapp_clicked',
      label: 'User shared story via WhatsApp',
    });
  };

  // Handle Email Modal Open
  const handleOpenEmailModal = () => {
    setEmail('');
    setEmailSuccess(null);
    setEmailError(null);
    setEmailModalOpen(true);

    // Log 'send_email_clicked' event
    ReactGA.event({
      category: 'User Interaction',
      action: 'send_email_clicked',
      label: 'User clicked send email button',
    });
  };

  // Handle Email Modal Close
  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  // Handle Send Email
  const handleSendEmail = async () => {
    setEmailSending(true);
    setEmailSuccess(null);
    setEmailError(null);

    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error('Ongeldig e-mailadres.');
      }

      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, uuid }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Fout bij het verzenden van de e-mail.');
      }

      const data = await response.json();
      if (data.message) {
        setEmailSuccess(data.message);
      } else {
        setEmailSuccess('E-mail succesvol verzonden!');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setEmailError(error.message);
    } finally {
      setEmailSending(false);
    }
  };

  const handleEnhanceStory = () => {
    // Check if the user is authenticated
    if (!isAuthenticated) {
      showSnackbar('Je moet ingelogd zijn om het verhaal uit te breiden.', 'info');
      showLoginModal(); // Show login prompt
      return;
    }
  
    // Check if the logged-in user's email matches the story creator's email
    if (email && user.email !== email) {
      showSnackbar('Alleen de gebruiker die dit verhaal heeft gemaakt, kan het verbeteren.', 'error');
      return;
    }
  
    // Check if user has credits
    if (userCredits === 0) {
      showSnackbar('Je hebt geen credits meer om het verhaal uit te breiden.', 'info');
      openAbonnementenModal(); // Open the subscription modal
      return;
    }
  
    // Proceed with enhancing the story
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  // Callback after ReCAPTCHA is successful
  const onReCAPTCHAChange = async (token) => {
    if (!token) {
      setEnhanceError('CAPTCHA is niet voltooid.');
      return;
    }

    setIsEnhancing(true);
    setEnhanceError(null);

    try {
      const values = {
        email,
        uuid,
        captchaToken: token,
        isPremium: isPremiumStory, // Use the actual premium status
      };

      let accessToken = '';
      if (isAuthenticated) {
        try {
          accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_AUDIENCE,
              scope: 'read:current_user',
            },
          });
        } catch (tokenError) {
          console.error('Error fetching access token:', tokenError);
          showSnackbar('Authenticatie is mislukt. Probeer het opnieuw.', 'error');
          setIsEnhancing(false);
          return;
        }
      }

      // Voeg de huidige timestamp toe aan de rate limiter
      addCreateStoryTimestamp();
      // Consume a credit by calling the /api/consume-credit endpoint


      // Call the enhance_story function with the obtained accessToken
      const enhancedUuid = await enhance_story(values, accessToken);
      console.log(`Enhancement voltooid voor UUID: ${enhancedUuid}`);

      

      // Reload the page to display the updated story
      window.location.reload();
    } catch (error) {
      console.error('Fout bij het verbeteren van het verhaal:', error);
      setEnhanceError(error.message);
      showSnackbar(error.message || 'Er is een onverwachte fout opgetreden.', 'error');
    } finally {
      setIsEnhancing(false);
    }
  };

  // Function to show Snackbar
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Function to handle closing Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Define actions for SpeedDial
  const speedDialActions = [
    {
      icon: <WhatsAppIcon />,
      name: 'Deel via WhatsApp',
      action: handleShare,
      tooltip: 'Deel via WhatsApp',
    },
    {
      icon: isGenerating ? <CircularProgress size={24} /> : <DownloadIcon />,
      name: 'Download Verhaal',
      action: handleDownload,
      tooltip: 'Download Verhaal',
      disabled: isGenerating,
    },
    {
      icon: <EmailIcon />,
      name: 'Verstuur verhaal',
      action: handleOpenEmailModal,
      tooltip: 'Verstuur verhaal via E-mail',
    },
    {
      icon: isEnhancing ? <CircularProgress size={24} /> : <EnhanceIcon />,
      name: 'Verhaal uitbreiden',
      action: handleEnhanceStory,
      tooltip: 'Verhaal uitbreiden',
      disabled: isEnhancing,
    },
  ];

  // Responsive positioning for SpeedDial
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade in={fadeIn} timeout={500}>
      <Container
        maxWidth="md"
        sx={{
          mt: 6,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: { xs: 2, sm: 4 },
        }}
      >
        {loadingStory ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : storyError ? (
          <Typography color="error" align="center" sx={{ my: 4 }}>
            {storyError}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Jouw Verhaal
            </Typography>

            {/* Image Section */}
            <Box
              sx={{
                my: 4,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loadingImage ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                  <CircularProgress />
                </Box>
              ) : imageError ? (
                <Typography color="error" align="center" sx={{ my: 4 }}>
                  Er is een probleem opgetreden bij het laden van de afbeelding.
                </Typography>
              ) : base64Image ? (
                <Box
                  component="img"
                  src={base64Image}
                  alt="Story Image"
                  sx={{
                    width: '100%',
                    height: 'auto', // Ensure the image takes its natural height
                    borderRadius: 2,
                  }}
                />
              ) : !imageUrl && !isPremiumStory ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={200} // Set to 50% of desired full height (e.g., 400px)
                  sx={{ borderRadius: 2 }}
                />
              ) : null}
            </Box>


            {/* Info Alert voor gebruikers zonder afbeelding en niet premium */}
            {!imageUrl && !isPremiumStory && (
              <Alert severity="info" sx={{ width: '100%', mb: 2 }}>
                Wil je bij je volgende verhaal een ook plaatjes zien? Log dan even in! Lukt het niet? Vraag dan hulp aan papa, mama, je juf of meester! (let op, na inloggen moet je een nieuw verhaal maken om dit te zien)
              </Alert>
            )}

            <Box sx={{ width: '100%' }}>
              <ReactMarkdown>{story}</ReactMarkdown>
            </Box>

            {/* "Verhaal uitbreiden" Button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <Button
                variant="contained"
                onClick={handleEnhanceStory}
                disabled={isEnhancing}
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  fontWeight: 600,
                  textTransform: 'none',
                  animation: `${pulsate} 2s infinite`,
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
                startIcon={isEnhancing ? <CircularProgress size={20} color="inherit" /> : <EnhanceIcon />}
              >
                {isEnhancing ? 'Uitbreiden...' : 'Verhaal uitbreiden'}
              </Button>
            </Box>

            <Box sx={{ width: '100%' }}>
              {/* Title Skeleton */}
              <Skeleton variant="text" height={40} width="60%" sx={{ mb: 3 }} />

              {/* Content Skeleton Lines with Varying Widths */}
              <Skeleton variant="text" height={30} width="95%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="90%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="85%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="80%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="75%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="85%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="70%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="90%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="80%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={30} width="95%" sx={{ mb: 1 }} />
            </Box>

            {/* Progress Bar for Enhancing */}
            {isEnhancing && (
              <Box sx={{ width: '100%', my: 2 }}>
                <LinearProgress />
                <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                  Verhaal wordt uitgebreid...
                </Typography>
              </Box>
            )}

            {/* The action buttons are now moved to the SpeedDial */}
          </>
        )}

        <SpeedDial
          ariaLabel="Verhaal Acties"
          sx={{
            position: 'fixed',
            bottom: theme.spacing(12),
            right: theme.spacing(2),
            // Override default FAB styles to accommodate text
            '& .MuiSpeedDial-fab': {
              width: '120px', // Adjust width to fit the text
              height: '48px', // Adjust height if necessary
              borderRadius: '8px', // Make it pill-shaped
              padding: theme.spacing(0.5, 2),
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
          icon={
            <Typography variant="button" sx={{ fontWeight: 'bold', color: 'white' }}>
              Deel!
            </Typography>
          }
          direction="up"
          FabProps={{
            color: 'primary',
          }}
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={
                <Typography variant="caption" noWrap>
                  {action.tooltip}
                </Typography>
              }
              onClick={action.action}
              tooltipOpen
              FabProps={{
                disabled: action.disabled,
              }}
              TooltipComponent={NoTooltip} // Use NoTooltip to disable tooltips
            />
          ))}
        </SpeedDial>

        {/* Email Modal */}
        <Dialog open={emailModalOpen} onClose={handleCloseEmailModal}>
          <DialogTitle>Verstuur verhaal naar E-mail</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="E-mailadres"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={emailSending}
            />
            {emailError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {emailError}
              </Alert>
            )}
            {emailSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {emailSuccess}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEmailModal} disabled={emailSending}>
              Annuleren
            </Button>
            <Button onClick={handleSendEmail} disabled={emailSending || emailSuccess}>
              {emailSending ? <CircularProgress size={20} /> : 'Verstuur'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for Alerts */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* ReCAPTCHA Component */}
        <Box sx={{ mt: 4 }}>
          <ReCAPTCHAComponent
            recaptchaRef={recaptchaRef}
            onReCAPTCHAChange={onReCAPTCHAChange}
          />
        </Box>
      </Container>
    </Fade>
  );
}

export default StoryDisplay;
