// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';

// Define the onRedirectCallback function
const onRedirectCallback = () => {
  console.log('Auth0Provider: onRedirectCallback called');
  
  // Retrieve the returnTo path from sessionStorage
  const returnTo = sessionStorage.getItem('returnTo') || window.location.pathname;
  console.log(`Auth0Provider: Redirecting to ${returnTo}`);
  
  // Remove query parameters related to Auth0 authentication
  const cleanUrl = window.location.origin + returnTo;
  
  // Redirect the browser to the desired path
  window.location.replace(cleanUrl);
  
  // Clean up sessionStorage
  sessionStorage.removeItem('returnTo');
};

// Ensure that all required environment variables are defined
const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

// Check if essential environment variables are set
if (
  !REACT_APP_AUTH0_DOMAIN ||
  !REACT_APP_AUTH0_CLIENT_ID ||
  !REACT_APP_AUTH0_AUDIENCE
) {
  console.error(
    'Missing one or more required environment variables: REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE'
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email read:current_user update:current_user_roles",
      }}
      cacheLocation="localstorage" // Ensure this is correctly set
      onRedirectCallback={onRedirectCallback} // Handle redirection after login
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);
