// src/utils/rateLimiter.js

/**
 * Haalt de timestamps van create_story verzoeken op uit sessionStorage.
 * @returns {number[]} Array van timestamps in milliseconden.
 */
export const getCreateStoryTimestamps = () => {
  const data = sessionStorage.getItem('createStoryTimestamps');
  return data ? JSON.parse(data) : [];
};

/**
* Voegt het huidige timestamp toe aan de create_story verzoeken.
*/
export const addCreateStoryTimestamp = () => {
  const timestamps = getCreateStoryTimestamps();
  const now = Date.now();
  timestamps.push(now);
  sessionStorage.setItem('createStoryTimestamps', JSON.stringify(timestamps));
};

/**
* Controleert of de gebruiker nog een create_story verzoek kan doen.
* @param {number} [minutes=15] Aantal minuten voor het rate limit venster.
* @returns {boolean} True als verzoek mogelijk is, anders false.
*/
export const canMakeCreateStoryRequest = (minutes = 15) => {
  const windowInMilliseconds = minutes * 60 * 1000; // Minuten omzetten naar milliseconden
  const windowStart = Date.now() - windowInMilliseconds;
  let timestamps = getCreateStoryTimestamps();

  // Filter oude timestamps buiten het huidige venster
  timestamps = timestamps.filter((timestamp) => timestamp > windowStart);
  sessionStorage.setItem('createStoryTimestamps', JSON.stringify(timestamps));

  // Controleer of het aantal resterende timestamps onder de limiet ligt (bijv. 1)
  return timestamps.length < 1;
};
