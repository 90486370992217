// src/pages/OverOnsPage.js

import React from 'react';
import { Typography, Box, Grid, Container, Button, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom'; // Of gebruik 'next/link' als je Next.js gebruikt
const OverOnsPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Over Ons
      </Typography>

      <Grid container spacing={4} alignItems="flex-start">
        {/* Profile Image */}
        <Grid item xs={12} sm={4}>
          <Box
            component="img"
            src="/profileimage.png" // Image from public folder

            alt="Foto van [Naam]"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '50%',
              boxShadow: 3,
            }}
            loading="lazy"
          />
        </Grid>

        {/* Profile Text */}
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" paragraph>
            Als moeder van twee kinderen weet ik hoe lastig het kan zijn om een kind enthousiast te maken voor lezen. Mijn jongste, een vrolijke jongen vol energie vond lezen helemaal niets. Elke avond probeerde ik hem te boeien met spannende verhalen en mooie illustraties, maar niets leek echt zijn aandacht te grijpen.
          </Typography>

          <Typography variant="body1" paragraph>
            Totdat ik het anders aanpakte: ik startte een website met verhalen vol avontuur en thema's zoals Star Wars en Minecraft, alles waar hij van houdt. Hij kan zelfs de hoofdpersonen kiezen. Tot mijn verrassing werkte het! Hij begon zelf om meer verhalen te vragen. Lezen werd iets waar hij naar uitkeek in plaats van een strijd.
          </Typography>

          <Typography variant="body1" paragraph>
            Dit succes inspireerde me om Verhalen-maker.nl te starten. Want als mijn zoons zoveel plezier uit verhalen kunnen halen, waarom zouden andere kinderen dat ook niet kunnen? Ons platform maakt verhalen toegankelijk en leuk voor iedereen. Want wij geloven dat lezen deuren opent naar een wereld vol kansen en dromen. De basisfunctionaliteiten zijn gratis, en voor een kleine bijdrage bieden we unieke extra’s aan. Zo kunnen we de website blijven vernieuwen en jou blijven verrassen.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Samen lezen stimuleren
          </Typography>

          <Typography variant="body1" paragraph>
            Daarnaast werken we samen met scholen om lezen op een leuke en toegankelijke manier te stimuleren. Mocht je als school interesse hebben naar meer informatie? Mail dan naar <MuiLink href="mailto:info@verhalen-maker.nl">info@verhalen-maker.nl</MuiLink>
          </Typography>

          <Typography variant="h5" gutterBottom>
            Tips om lezen leuk te maken
          </Typography>

          <Typography variant="body1" paragraph>
            Naast Verhalen-maker.nl zijn er andere fijne manieren om kinderen enthousiast te maken voor lezen:
          </Typography>

          <Box component="ul" sx={{ textAlign: 'left', display: 'inline-block', mb: 2 }}>
            <li>
              <MuiLink href="https://www.zwijsen.nl/app/uploads/zwijsen-files/leesbingo-blauw.pdf" target="_blank" rel="noopener noreferrer">
                Leesbingo Blauw
              </MuiLink>: Probeer een leesbingo voor een wisselend leesmoment.
            </li>
            <li>
              <MuiLink href="https://www.zwijsen.nl/app/uploads/zwijsen-files/leesbingo-groen.pdf" target="_blank" rel="noopener noreferrer">
                Leesbingo Groen
              </MuiLink>: Probeer een leesbingo voor een wisselend leesmoment.
            </li>
            <li>Recepten voorlezen tijdens het koken: Combineer lezen met een leuke activiteit.</li>
            <li>Supermarkt-lezen: Lees samen verpakkingen in de supermarkt voor een leerzaam uitje.</li>
            <li>Bibliotheekbezoek: Laat je kind een stripboek en een leesboek kiezen voor variatie.</li>
          </Box>

          <Typography variant="body1" fontWeight="bold">
            Maar de beste tip: maak het lezen vooral leuk en gezellig!
          </Typography>

        </Grid>
      </Grid>
    </Container>
  );
};

export default OverOnsPage;
