// src/pages/homepage_components/HeroSection.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import adventureImage from '../../adventure.png';

const HeroSection = () => {
  return (
    <>
      {/* Hero Image */}
      <Box
        sx={{
          width: '100%',
          height: { xs: '200px', sm: '300px', md: '400px' },
          backgroundImage: `url(${adventureImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 2,
          mb: 4,
        }}
      />

      {/* Main Heading */}
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ mt: 4, mb: 4 }}
      >
        Maak je eigen superleuke kinderavontuur! 🌟🚀
      </Typography>

      {/* Subheading */}
      <Typography
        variant="subtitle1"
        align="center"
        gutterBottom
        sx={{ mt: 2, mb: 6 }}
      >
        Welkom bij onze verhalenmaker! ✨ In een paar simpele stapjes maak je een persoonlijk verhaal voor jouw kind(eren). Perfect om van te genieten voor het slapengaan. Volg de stappen hieronder en laat de magie beginnen! 🌙📖
      </Typography>
    </>
  );
};

export default HeroSection;
