// src/components/Navigation.js

import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Avatar,
  Tooltip,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreateIcon from '@mui/icons-material/Create';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useAuth0 } from '@auth0/auth0-react';
import { UserStatusContext } from '../contexts/UserStatusContext'; // Import the context
import { useAbonnementenModal } from './AbonnementenModal';

const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const drawerWidth = 240;

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userCredits, setUserCredits] = useState(null); // Initieel null
  const [creditsModalShown, setCreditsModalShown] = useState(false); // Nieuwe staat

  const { openAbonnementenModal } = useAbonnementenModal();

  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const { subscriptionStatus, userCredits: contextUserCredits } = useContext(UserStatusContext); // Consume context

  useEffect(() => {
    setUserCredits(contextUserCredits);
  }, [contextUserCredits]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateHome = () => {
    navigate('/');
  };

  const handleNavigateBibliotheek = () => {
    navigate('/mijn-verhalen-bibliotheek');
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  // Open the Abonnementen modal if the status is 'unverified'
  useEffect(() => {
    if (subscriptionStatus === 'unverified') {
      openAbonnementenModal();
    }
  }, [subscriptionStatus, openAbonnementenModal]);

  // Open the Abonnementen modal if userCredits is 0 and modal not yet shown
  useEffect(() => {
    console.log('user credits is: ', userCredits)
    console.log('user status is is: ', subscriptionStatus)
    // Alleen triggeren als userCredits zijn geladen (niet null)
    if (userCredits === 0 && !creditsModalShown) {
      openAbonnementenModal();
      setCreditsModalShown(true);
    }
  }, [userCredits, creditsModalShown, openAbonnementenModal]);

  // Function to reload the page
  const handleReload = () => {
    window.location.reload();
  };

  // Function to handle login initiated from the Navigation Bar
  const handleLogin = () => {
    console.log('Navigation: Initiating login');
    console.log('Navigation: Current location:', location.pathname + location.search);

    // Store the return path in sessionStorage
    sessionStorage.setItem('returnTo', location.pathname + location.search);

    // Initiate Auth0 login
    loginWithRedirect().catch((error) => {
      console.error('Navigation: loginWithRedirect error:', error);
    });
  };

  // Drawer content
  const drawer = (
    <Box onClick={isMobile ? handleDrawerToggle : undefined} sx={{ textAlign: 'center' }}>
      <List>
        {/* Maak een verhaal */}
        <ListItem
          button
          component={NavLink}
          to="/"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Maak een verhaal" />
        </ListItem>

        {/* Veel gestelde vragen */}
        <ListItem
          button
          component={NavLink}
          to="/veel-gestelde-vragen"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Veel gestelde vragen" />
        </ListItem>

        {/* Contact */}
        <ListItem
          button
          component={NavLink}
          to="/contact"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>

        {/* Over Ons */}
        <ListItem
          button
          component={NavLink}
          to="/over-ons"
          sx={{
            '&.active': {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Over ons" />
        </ListItem>

        {/* Mijn bibliotheek */}
        {isAuthenticated && (
          <ListItem
            button
            component={NavLink}
            to="/mijn-verhalen-bibliotheek"
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary="Mijn bibliotheek" />
          </ListItem>
        )}

        {/* Abonnementen - Only show if authenticated */}
        {isAuthenticated && (
          <ListItem
            button
            onClick={openAbonnementenModal}
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText primary="Verhalen bundels" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box>
      {/* AppBar fixed at the bottom */}
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {/* Hamburger icon on the left */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Conditionally render the "Verhalen bibliotheek" icon when authenticated */}
          {isAuthenticated && (
            <Tooltip title="Verhalen bibliotheek">
              <IconButton
                color="inherit"
                aria-label="open verhalen bibliotheek"
                onClick={handleNavigateBibliotheek}
                sx={{ mr: 2 }}
              >
                <LibraryBooksIcon />
              </IconButton>
            </Tooltip>
          )}

          {/* Add the new "Create" icon */}
          <Tooltip title="Home">
            <IconButton
              color="inherit"
              aria-label="home"
              onClick={handleNavigateHome}
              sx={{ mr: 2 }}
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>

          {/* Abonnementen Button - Only show if authenticated */}
          {isAuthenticated && (
            <Tooltip title="Bundels">
              <IconButton
                color="inherit"
                aria-label="bundels"
                onClick={openAbonnementenModal}
                sx={{ mr: 2 }}
              >
                <SubscriptionsIcon />
              </IconButton>
            </Tooltip>
          )}

          {/* Title */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: 'inherit',
              cursor: 'pointer',
              display: isMobile ? 'none' : 'block', // Hide on mobile
            }}
            onClick={handleNavigateHome}
          >
            Verhalen-maker.nl
          </Typography>

          {/* Spacer Box to push the authentication section to the right */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Authentication Section in AppBar */}
          {isAuthenticated && user ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* User Avatar with Tooltip and Badge */}
              <Tooltip title={user.name}>
                <IconButton sx={{ p: 0, mr: 1 }}>
                  <Badge
                    badgeContent={userCredits}
                    color="success"
                    max={9999}
                    overlap="circular"
                    showZero
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <Avatar alt={user.name} src={user.picture} />
                  </Badge>
                </IconButton>
              </Tooltip>
              {/* Logout Button */}
              <Button color="inherit" onClick={handleLogout}>
                Log uit
              </Button>
            </Box>
          ) : (
            // Login Button with "Log in of meld je aan" text
            <Button color="inherit" onClick={handleLogin}>
              Log in of meld je aan! 🚀✨
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>

      {/* Optional: Add a Toolbar spacer to prevent content from being hidden behind the AppBar */}
      <Toolbar sx={{ display: { xs: 'none', md: 'none' } }} />

      {/* Verification Modal */}
      <Dialog
        open={subscriptionStatus === 'unverified'}
        onClose={() => {}}
        aria-labelledby="verificatie-dialog-title"
        aria-describedby="verificatie-dialog-description"
      >
        <DialogTitle id="verificatie-dialog-title">E-mail verificatie vereist</DialogTitle>
        <DialogContent>
          <DialogContentText id="verificatie-dialog-description">
            Hoi! Kijk even in je e-mail en klik op de bevestigingslink.
            Daarna kun je de pagina verversen en extra mooie verhaaltjes maken! 😊
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReload} color="primary" variant="contained">
            Pagina herladen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Navigation;
