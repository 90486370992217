    // src/contexts/UserStatusContext.js

    import React, { createContext, useState, useEffect } from 'react';
    import { useAuth0 } from '@auth0/auth0-react';
    import axios from 'axios';
    import { useLocation } from 'react-router-dom';

    export const UserStatusContext = createContext();

    const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

    export const UserStatusProvider = ({ children }) => {
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [userCredits, setUserCredits] = useState(null);
    const location = useLocation(); // Get current location

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
        if (isAuthenticated && user) {
            try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                audience: AUTH0_AUDIENCE,
                scope: 'read:current_user',
                },
                cacheMode: 'on', // Options: 'on', 'off', 'cache-only'
            });

            const headers = {
                'Content-Type': 'application/json',
            };

            if (accessToken) {
                headers['Authorization'] = `Bearer ${accessToken}`;
            }

            const response = await axios.post(
                '/api/user-status',
                {
                email: user.email,
                sub: user.sub,
                },
                { headers }
            );

            setSubscriptionStatus(response.data.user_subscription_status);
            setUserCredits(response.data.user_credits);
            console.log('User Subscription Credits:', response.data.user_credits);
            console.log('User Subscription Status:', response.data.user_subscription_status);
            } catch (error) {
            console.error('Error fetching subscription status:', error);
            setSubscriptionStatus(null);
            setUserCredits(null);
            }
        } else {
            setSubscriptionStatus(null);
            setUserCredits(null);
        }
        };

        fetchSubscriptionStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user, getAccessTokenSilently, AUTH0_AUDIENCE, location.pathname]);

    return (
        <UserStatusContext.Provider value={{ subscriptionStatus, userCredits }}>
        {children}
        </UserStatusContext.Provider>
    );
    };
