// src/pages/VeelGesteldeVragen.js

import React from 'react';
import { Typography, Box, Container, List, ListItem, ListItemText } from '@mui/material';

const VeelGesteldeVragen = () => {
  const vragenAntwoorden = [
    {
      vraag: "Blijft de website gratis en advertentie vrij?",
      antwoord: "De website Verhalen-maker.nl is gratis te gebruiken. Het plan is dit zo te houden! Vanuit de ambitie om het lezen leuk te maken en de leesvaardigheid te verhogen willen we dat de site en de verhalen voor iedereen toegankelijk zijn. Wel overwegen we in de toekomst extra premium functies toe te voegen waarvoor betaald moet worden om zo de website advertentie vrij te houden, om de operationele kosten te dekken en je een betere ervaring te bieden. Maar de basisfunctionaliteit blijft gratis beschikbaar."
    },
    {
      vraag: "Is dit hetzelfde als ChatGPT?",
      antwoord: "We maken gebruik van een vergelijkbare technologie als ChatGPT. Onze website is speciaal ontworpen voor het genereren van verhalen. We hebben het aangepast en geoptimaliseerd voor kinderen en volwassenen om zo hun verhalen te maken."
    },
    {
      vraag: "Gebruiken jullie kunstmatige intelligentie (KI) of artificiële intelligentie (AI)?",
      antwoord: "Net als vele anderen wordt door ons gebruik gemaakt van deze technologie om verhalen te genereren. Het geeft ons de mogelijkheid om op een creatieve en toegankelijke manier unieke verhalen aan te bieden."
    },
    {
      vraag: "Is de website alleen voor kinderen?",
      antwoord: "Iedereen die verhalen wil maken kan de website gebruiken. We hebben ervoor gezorgd dat de site kindvriendelijk is, maar volwassenen kunnen er ook veel plezier aan beleven. Iedereen die van verhalen houdt kan onze website gebruiken."
    },
    {
      vraag: "Waarom moeten we inloggen?",
      antwoord: "Door in te loggen kun je je verhalen opslaan, beheren en later teruglezen. Het stelt ons ook in de gelegenheid om je een meer persoonlijkere ervaring te bieden, zoals het onthouden van je voorkeuren."
    },
    {
      vraag: "Blijven jullie voor altijd bestaan?",
      antwoord: "We zijn een jong gezin en eerst vanuit onszelf en later vanuit onze ambitie hebben we de website ontwikkeld. We zijn vastbesloten om de website zo lang als mogelijk beschikbaar te houden en voortdurend te verbeteren. Het voortbestaan van de website is natuurlijk ook afhankelijk van andere zaken, zoals interesse en kosten."
    },
    {
      vraag: "Hoe zorgen jullie ervoor dat de data veilig blijft?",
      antwoord: "De privacy en gegevensbeveiliging nemen we zeer serieus. We gebruiken geavanceerde beveiligingstechnieken om je gegevens te beschermen en verzamelen alleen de minimale informatie die nodig is voor het beheer van de website. We delen je gegevens nooit met derden zonder jouw toestemming."
    },
    {
      vraag: "Waarom klopt het leesniveau niet altijd?",
      antwoord: "We doen ons best doen om het leesniveau nauwkeurig af te stemmen op je keuze. Het kan soms variëren omdat we gebruik maken van de technologie van AI en vanwege de manier waarop AI de verhalen genereert. We monitoren van tijd tot tijd de uitkomsten en werken continu aan het verbeteren van deze functie."
    },
    {
      vraag: "Worden teksten geredigeerd?",
      antwoord: "Zoals hiervoor al is beschreven worden de verhalen volledig automatisch gegenereerd door AI en worden niet handmatig geredigeerd. Aan de ene kant zorgt dit voor originele en unieke verhalen, maar het kan ook betekenen dat er af en toe kleine foutjes of onvolkomenheden in staan."
    },
    {
      vraag: "Hoelang worden verhalen bewaard?",
      antwoord: "Verhalen blijven opgeslagen zolang je account actief is. Als je jouw account verwijdert, worden de verhalen ook verwijderd."
    },
    {
      vraag: "Vanuit mijn basisfunctionaliteit zit ik aan mijn verhalen limiet, wat nu?",
      antwoord: "Als je je limiet hebt bereikt, kun je door in te loggen of een account aan te maken kun je hogere limieten verkrijgen en kun je nog meer verhalen genereren."
    },
    {
      vraag: "Mag ik het delen?",
      antwoord: "Je mag jouw verhalen altijd delen met anderen zoals vrienden, familie of op sociale media. Zo inspireer je anderen ook om verhalen te maken. Dit past helemaal in onze ambitie om het lezen leuk te maken en de leesvaardigheid te verhogen"
    }
  ];

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Veel Gestelde Vragen
      </Typography>
      <Box>
        <List>
          {vragenAntwoorden.map((item, index) => (
            <ListItem key={index} sx={{ mb: 2 }}>
              <ListItemText
                primary={<Typography variant="h6">{item.vraag}</Typography>}
                secondary={<Typography variant="body1">{item.antwoord}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default VeelGesteldeVragen;
